import { render, staticRenderFns } from "./CategoryCommunity.vue?vue&type=template&id=bcac2526&scoped=true"
import script from "./CategoryCommunity.vue?vue&type=script&lang=js"
export * from "./CategoryCommunity.vue?vue&type=script&lang=js"
import style0 from "./CategoryCommunity.vue?vue&type=style&index=0&id=bcac2526&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcac2526",
  null
  
)

export default component.exports