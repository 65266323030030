import * as Api from '@/api/category/category'
import CourseCard from '@/components/CourseCard/CourseCard.vue'
import { switchCategory } from '@/utils/utils'
import { activityType } from '@/enums/API'

export default {
  data () {
    return {
      courseData: [],
      communityType: '',
      courseList: {},
      category_id: 1,
      oldPagination: {},
      soonPagination: {}
    }
  },
  mounted () {
    this.category_id = switchCategory(this.$route.path)
    this.getOldCourseList()
    this.communityType = 'soon'
    this.getLiveCourseList()
    this.getOldCourseList()
    window.addEventListener('scroll', this.lazyLoading) // 滚动到底部，再加载的处理事件
  },
  methods: {
    changeCommunityType ( type ) {
      this.communityType = type
      if (type === 'soon') {
        if (this.courseList.soon) {
          this.courseData = this.courseList.soon
        } else {
          this.getLiveCourseList()
        }
      } else {
        if (this.courseList.old) {
          this.courseData = this.courseList.old
        } else {
          this.getOldCourseList()
        }
      }
    },
    getLiveCourseList ( page = 1 ) {
      let params = {
        category_id: this.category_id,
        page: page
      }
      Api.activityCommunityGroupsAll(activityType.publicClass, params, ( res, pagination ) => {
        res.forEach(course => {
          course.course_mode = 1
          course.course_type = 1
        })
        this.courseList.soon = res
        this.courseData = res
        this.soonPagination = pagination
        if (res.length === 0) {
          this.getOldCourseList()
          this.communityType = 'old'
        }
      })
    },
    getOldCourseList ( page = 1 ) {
      let params = {
        course_type: this.constant.COURSE_TYPE.COMMUNITY,
        category_id: this.category_id,
        page: page
      }
      Api.course('get', params, ( res, pagination ) => {
        this.courseList.old = res
        this.courseData = res
        this.oldPagination = pagination
      })
    },

    // 往期视频请求
    reqOldVideo ( page ) {
      let params = {
        course_type: this.constant.COURSE_TYPE.COMMUNITY,
        category_id: this.category_id,
        page: page
      }
      Api.course('get', params, ( res, pagination ) => {
        res.forEach(item => {
          this.courseData.push(item)
        })
        this.oldPagination = pagination
      })
    },

    // 直播课请求
    reqLive () {
      let params = {
        category_id: this.category_id,
        page: page
      }
      Api.activityCommunityGroups(activityType.publicClass, params, ( res, pagination ) => {
        res.forEach(course => {
          course.course_mode = 1
          course.course_type = 1
          this.courseData.push(course)
        })
        this.soonPagination = pagination
      })
    },

    lazyLoading () { // 滚动到底部，再加载的处理事件
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const clientHeight = document.documentElement.clientHeight
      const scrollHeight = document.documentElement.scrollHeight

      if (scrollTop + clientHeight >= scrollHeight) {
        // 滚动到底部
        if (this.communityType === 'old') {
          if (this.oldPagination.next !== null) { // 有下一页才会请求
            this.reqOldVideo(this.oldPagination.current_page + 1)
          }
        } else {
          if (this.oldPagination.next !== null) { // 有下一页才会请求
            this.reqLive(this.soonPagination.current_page + 1)
          }
        }
      }
    },

  },
  components: {
    CourseCard
  }
}
