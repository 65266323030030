<template>
  <div class="ielts-community">

    <div class="content">
      <template v-if="courseList?.soon?.length>0">
        <div class="title-box">
          直播课
        </div>
        <div class="lists" >
          <CourseCard v-for="(course,index) in courseList.soon" :courseData="course"
                      :key="index"
                      detailPage="activityDetail"></CourseCard>
        </div>
      </template>
      <template v-if="courseList?.old?.length>0">
        <div class="title-box">
          往期视频
        </div>
        <div class="lists" >
          <CourseCard v-for="(course,index) in courseList.old" :courseData="course"
                      :key="index"
                      detailPage="pastCourseDetail"></CourseCard>
        </div>
      </template>
    </div>
    <el-pagination
        v-show="communityType === 'soon'"
        @size-change="getLiveCourseList"
        @current-change="getLiveCourseList"
        v-if="soonPagination"
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :page-size="soonPagination.per_page"
        :total="soonPagination.total">
    </el-pagination>
    <el-pagination
        v-show="communityType === 'old'"
        @size-change="getOldCourseList"
        @current-change="getOldCourseList"
        v-if="oldPagination"
        background
        :hide-on-single-page="true"
        layout="prev, pager, next"
        :page-size="oldPagination.per_page"
        :total="oldPagination.total">
    </el-pagination>
  </div>
</template>

<script>
  import CategoryCommunityJS from './CategoryCommunity.js'

  export default CategoryCommunityJS
</script>

<style lang="less" scoped>
  .ielts-community {
    background: #fff;
    overflow: hidden;
    .title-box {
      box-sizing: border-box;
      width: 1200px;
      margin: 0 auto 30px;
      overflow: hidden;
      text-align: left;
      flex-shrink: 0;
      font-size: 24px;
      font-weight: bold;
      color: #000;

    }

    & > .content {
      margin-top: 40px;

      & > .lists {
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;

        & > .course-card:nth-child(4n) {
          margin-right: 0;
        }

        /deep/ .course-card {
          width: 272px;
          height: 294px;
          border: 1px solid #E6E6E6;
          border-radius: 5px;
          background: #fff;
          float: left;
          margin-right: 37px;
          box-sizing: border-box;
          margin-bottom: 30px;
          & > a {
            & > .top {
              width: 272px;
              height: 153px;
              border-radius: 5px 5px 0 0;
              margin: -1px 0 0 -1px;
            }

            & > .desc {
              height: 140px;
              box-sizing: border-box;
              padding: 8px 16px 18px;

              & > .first-line {
                font-size: 16px;
              }

              & > .second-line {
                font-size: 14px;
                height: 20px;
              }
              &>.third-line{
                padding: 0 18px;
              }
            }
          }
        }
      }
    }
    .el-pagination {
      padding: 20px;
      float: right;
    }
  }
</style>
